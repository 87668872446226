@import "../../../../../sass/Variables.scss";

.zone-wrapper {
  display: flex;
  &.list {
    flex-direction: column !important;
  }
  &.card {
    flex-wrap: wrap;
    margin-top: 0.25rem;
    background-color: $gray-100;
    .zone {
      flex-direction: column;
      max-width: 100%;
      min-width: 7rem;
      row-gap: 0.25rem;
      padding: 1rem;
      margin: 0.125rem;
      background-color: $col-white;
    }
    .zone-label {
      font-weight: bold;
      margin-left: 0.125rem;
    }
  }
  .zone-container {
    column-gap: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .zone-setpoint {
    display: flex;
    align-items: center;
  }
  .zone {
    display: flex;
    flex-direction: row;
    margin-left: 0.065rem;
    margin-right: 0.125rem;
    max-width: 100%;
  }
}
