@import "../../../sass/Variables.scss";

.dropdown {
  .btn {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: 0.75rem;
    font-weight: normal;
    border: 0.006rem solid $gray-200;
    border-bottom: 0.0006rem solid $gray-400;
    box-shadow: none !important;
    padding: 0.25rem 0.5rem;
    &:focus,
    &:focus-within {
      border-bottom: 0.006rem solid $blue-light;
    }
  }
  &-title {
    display: flex;
    flex: 1;
    margin-right: 0.2rem;
    font-weight: normal;
    align-items: center;
    justify-content: space-between;
  }
  &-value {
    display: inline;
    font-weight: normal;
  }
  &-clear {
    width: 0.75rem !important;
    margin-right: 0.2rem;
  }
  &-option {
    font-size: 0.875rem;
    padding: 0.5rem 0.75rem;
  }
  &-options {
    max-height: 15rem;
    overflow-y: auto;
  }
  &-search {
    padding: 0.5rem 0.5rem;
    position: sticky;
    z-index: 1;
  }
  &-search-input {
    width: 100%;
    border-radius: $border-radius;
  }
}

.dropdown-toggle::after {
  vertical-align: middle !important;
  color: $gray-450 !important;
  font-size: 1.25rem;
}

.dropdown-menu {
  width: 100%;
  max-height: 20rem;
  overflow: auto;
}
