.task-layer-container {
  position: absolute;
  z-index: 2;
  display: flex;
  top: 0.6rem;
  left: 14.5rem;
}
@media only screen and (max-width: 991.98px) {
  .task-layer-container {
    flex-direction: column;
    top: 16.25rem;
    right: 0.5rem;
    left: unset;
  }
}
