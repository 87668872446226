@import "../../../../sass/Variables.scss";

.traceview-info {
  display: flex;
  flex-direction: column;
  row-gap: 0.05rem;
  &-loader {
    height: 7rem !important;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &-address {
    display: flex;
    flex-direction: row;
  }
  &-details {
    margin-left: 0.3rem;
  }
  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: 0.03rem;
    font-weight: bold;
  }
  &-icon {
    width: 1.75rem;
    height: 1.75rem;
  }
  &-close-icon {
    cursor: pointer;
    position: absolute;
    top: 0.35rem;
    left: 0.35rem;
    width: 0.65rem;
    height: 0.65rem;
    &:hover {
      background-color: $gray-150;
    }
  }
  &-error,
  &-retry {
    img {
      width: 0.9rem;
      height: 0.9rem;
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }
}
