@import "../../../sass/Variables.scss";

.details-view {
  display: flex;
  flex-direction: column;
  margin: 0rem;
  font-size: 0.9rem;
  font-weight: 550;
  background-color: $gray-100;
  height: 100% !important;
  padding-top: 0.5rem;
  z-index: 3;
  .error-toast-message {
    position: absolute;
    bottom: 1rem;
    width: 100%;
  }
  .details-panel {
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
    height: calc(100% - 5.65rem);
    overflow: hidden;
    &-mobile {
      height: calc(100% - 5.25rem) !important;
    }
    &-dispatch {
      height: calc(100% - 8.9rem) !important;
    }
  }
  .nav-link {
    padding: 0.65rem 1.5rem !important;
  }
}

.tab-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.063rem solid $col_gray_1 !important;
}

.details-actionBtn {
  display: flex;
  justify-content: flex-end;
  margin: 0.85rem;
}

.vehicles-view {
  .conversation-header {
    padding: 0.325rem !important;
  }
  .conversation-threads-container {
    padding: 1rem !important;
  }
}
.details-view {
  .conversation-vehicle {
    margin-left: 0.25rem;
    .crew-info {
      .vehicles-title-card {
        background-color: $gray-200;
        height: 2rem;
        border-radius: 0.15rem;
      }
      .vehicles-title-card,
      .vehicle-title,
      .title-block {
        max-width: 100%;
      }
      .driver {
        width: 1.2rem;
      }
      .vehicle-title {
        :hover {
          text-decoration: none;
        }
      }
    }
  }
  .msg-btn {
    display: flex;
    align-items: center;
  }
}
//Mobile screen
@media only screen and (max-width: 576px) {
  .details-view {
    .nav-link {
      padding: 0.65rem !important;
    }
  }
}

@media only screen and (max-width: 991.98px) {
  .details-view {
    .conversation {
      &-header {
        max-width: calc(100vw - 0.5rem);
      }
    }
    .conversation-vehicle {
      max-width: 80%;
    }
  }
}

@media only screen and (min-width: 992px) {
  .details-view {
    .conversation {
      &-header {
        max-width: calc(100vw - 48rem);
      }
    }
    .conversation-vehicle {
      max-width: 70%;
    }
  }
}
