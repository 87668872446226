@import "../../../../../../sass/Variables.scss";

.list-block {
  height: 100%;
  .autocomplete {
    &-container {
      height: 100%;
      width: 100%;
    }
    &-list {
      height: 100%;
      width: 100%;
      margin-top: 0.3rem;
    }
    &-list-item {
      padding: 0rem 0.3rem;
    }
    &-text {
      font-size: 0.875rem;
    }
    &-search {
      margin: 0 0.3rem;
    }
    &-info {
      margin-top: 6rem;
    }
  }
  .list-item-container {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: $gray-100;
    border-radius: 0.313rem;
    padding: 0.375rem 0.875rem;
    &:hover {
      cursor: pointer;
      background-color: $gray-150 !important;
    }
    &-selected {
      background-color: $gray-200;
    }
  }
  .error-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin-top: 6rem;
  }
  .react-loading-skeleton {
    margin-bottom: 0.375rem;
  }
}
