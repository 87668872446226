@import "../../../../../../sass/Variables.scss";

.trace-map-footer {
  display: flex;
  padding: 0.33rem 1.25rem;
  justify-content: space-evenly;
  background-color: $col_white;
  border-radius: $border-radius;
  svg {
    path {
      &.fillColor {
        fill: $col_trimble_blue_dark;
      }
    }
  }
}

.trace-map-filter-btn {
  transform: scale(1.25);
}
