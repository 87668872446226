@import "../../../../../../sass/Variables.scss";
.trace-vehicles {
  &-container {
    display: flex;
    background-color: $col_white;
    flex-direction: column;
    width: 100%;
  }
  &-title {
    display: flex;
    flex-direction: column;
    padding-top: 1.25rem;
    font-weight: bold;
    padding-left: 0.75rem;
    padding-bottom: 1rem;
  }
}
.vehicle-list {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0.125rem 0;
  }
}
