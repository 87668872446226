.zone-details {
  &-container {
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;
    margin-top: 0.25rem;
  }
  &-temp,
  &-air {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
  &-air {
    align-items: end;
  }
}
