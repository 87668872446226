@import "../../../../../../sass/Variables.scss";

.traces-list {
  &-container {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    contain: strict;
    animation: fadeIn 0.2s;
    .trace-details-container {
      font-size: 0.75rem;
      padding: 0.25rem 0.5rem;
      row-gap: 0.2rem;
    }
  }
  &-panel {
    position: absolute;
    width: 100%;
    padding: 0.2rem;
  }
  &-row {
    display: flex;
    flex-direction: column;
    margin: 0 0.25rem;
    border-radius: 0.125rem;
    border-bottom: 0.131rem solid $gray-200;
    &-selected {
      background-color: $gray-150;
    }
    &-highlight {
      background-color: $gray-100;
    }
  }
  &-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &-info {
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0.5rem;
    max-width: 80%;
    min-width: 80%;
  }
}
.trace {
  &-title {
    font-size: 0.875rem;
    font-weight: 600;
    color: $gray-400;
  }
  &-timestamp {
    padding: 0.15rem 0;
    font-size: 0.75rem;
  }
  &-error {
    font-size: 0.875rem;
    &-icon {
      height: 0.75rem;
      width: 0.75rem;
      margin: 0.33rem 0.2rem;
    }
  }
}
.trace-accordion-arrow {
  padding: 0.75rem;
  border-radius: 0.125rem;
  &-up {
    transform: rotate(0deg);
  }
  &-down {
    transform: rotate(-180deg);
  }
  &:hover {
    background-color: $gray-200;
  }
}
